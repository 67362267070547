import React from "react";
import {Button, Container, Header, Icon, Message, Segment} from "semantic-ui-react";
import {useHistory} from 'react-router-dom';

const Home = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push("/mulai");
  }
  const list = [
    'Peserta Wajib mengaktifkan camera dan join audio. Pastikan perangkat Saudara berfungsi dengan baik.',
    'Setiap soal mempunyai waktu tersendiri dan apabila waktu sudah habis, soal berikutnya akan muncul secara otomatis',
    'Selama proses berlangsung, mohon untuk tidak me-refresh halaman web.',
    'Berdoa terlebih dahulu sebelum memulai wawancara'
  ]
  return (
    <Container text className={"isi"}>
      <Segment raised>
        <Header size={"huge"} textAlign={"center"}>
          <Header.Content>
            Selamat Datang pada sesi Wawancara
          </Header.Content>
        </Header>
        <Message header='Tata Tertib Wawancara :' list={list}/>
        <Message visible>Apabila sudah siap, silahkan tekan tombol mulai</Message>
        <Message visible>Selamat mengerjakan!</Message>
      </Segment>
      <Button primary animated floated='right' onClick={handleClick}>
        <Button.Content visible>Mulai</Button.Content>
        <Button.Content hidden>
          <Icon name='arrow right'/>
        </Button.Content>
      </Button>
    </Container>
  )
};
export default Home;