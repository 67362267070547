import React from "react";
import {Container, Image, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";

function Navbar() {
  return (
    <Menu fixed="top">
      <Container>
        <Menu.Item header as={Link} exact={"true"} to="/">
          <Image size="mini" alt="logo" src='/logo.png' style={{marginRight: "1.5em"}}/>Dinas CKTRP
        </Menu.Item>
      </Container>
    </Menu>);
}

export default Navbar;
