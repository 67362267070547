import React, {Component} from "react";
import {Container, Dropdown, Grid, GridRow, Header, Segment} from "semantic-ui-react";
import ConfirmationModal from "../ConfirmationModal";

class Mulai extends Component {
  modalInitialState = {
    isOpen: false,
    header: "",
    content: "",
    onAction: null,
    onClose: null
  };
  state = {
    jabatanOptions: [],
    valueJabatan: "",
    modal: {...this.modalInitialState}
  }
  handleDropdownChange = (e, {value}) => {
    if (value === 1) {
      const jabatan1 = [
        {key: 1, text: 'Assistant Professional Staf Digitalisasi Arsip', value: 11, disabled: true}
        , {key: 2, text: 'Pengolah Data', value: 12, disabled: true}
        , {key: 3, text: 'Administrasi Umum (Operator Komputer / Data Entry)', value: 13, disabled: true}
      ]
      this.setState({jabatanOptions: jabatan1});
    } else if (value === 2) {
      const jabatan2 = [
        {
          key: 1,
          text: 'Tenaga Ahli Muda Golongan II-A (Senior GIS Development & Data Analysis)',
          value: 21, disabled: true
        }
        , {key: 2, text: 'Tenaga Ahli Muda Golongan II-A (Senior Web Developer)', value: 22, disabled: true}
        , {key: 3, text: 'Tenaga Ahli Muda Golongan II-A (Senior GeoDatabase Administrator)', value: 23, disabled: true}
        , {key: 4, text: 'Tenaga Ahli Muda Golongan II-A (Product Development)', value: 24, disabled: true}
        , {key: 5, text: 'Tenaga Ahli Pratama Golongan I-C (GIS Developer Team Leader)', value: 25, disabled: true}
        , {key: 6, text: 'Tenaga Ahli Pratama Golongan I-C (Senior GIS Developer)', value: 26, disabled: true}
        , {
          key: 7,
          text: 'Tenaga Ahli Pratama Golongan I-C (Publication and Communication Team Leader)',
          value: 27, disabled: true
        }
        , {key: 8, text: 'Assistant Professional Staff (Mobile Apps Developer)', value: 28, disabled: true}
        , {key: 9, text: 'Assistant Professional Staff (Flutter Developer)', value: 29, disabled: true}
        , {key: 10, text: 'Assistant Professional Staff (Multimedia & Design Grafis)', value: 210, disabled: true}
        , {key: 11, text: 'Assistant Professional Staff (Data Analytic)', value: 211, disabled: true}
        , {key: 12, text: 'Assistant Professional Staff (Junior GeoDatabase Administrator)', value: 212, disabled: true}
        , {key: 13, text: 'Assistant Professional Staff (IT Development)', value: 213, disabled: true}
        , {key: 14, text: 'Assistant Professional Staff (GIS Developer)', value: 214, disabled: true}
        , {key: 15, text: 'Assistant Professional Staff (UI / UX Designer)', value: 215, disabled: true}
        , {key: 16, text: 'Facilitator (Communications and Outreach Officer)', value: 216, disabled: true}
        , {key: 17, text: 'Data Entry (Administration Officer)', value: 217, disabled: true}
      ]
      this.setState({jabatanOptions: jabatan2});
    } else if (value === 3) {
      const jabatan3 = [
        {key: 11, text: 'Senior Security System', value: 40, disabled: false}
        , {key: 4, text: 'Assistant Professional Staff (Junior Software Developer)', value: 34, disabled: false}
        , {key: 10, text: 'Assistant Professional Staff (UI / UX Designer)', value: 310, disabled: true}
        , {key: 1, text: 'Tenaga Ahli Muda Golongan II-A (Senior Software Developer)', value: 31, disabled: true}
        , {key: 2, text: 'Tenaga Ahli Muda Golongan II-A (Senior GIS Infrastruktur Enginer)', value: 32, disabled: true}
        , {
          key: 3,
          text: 'Tenaga Ahli Muda Golongan II-A (Senior System and Network Administrator)',
          value: 33, disabled: true
        }
        , {key: 5, text: 'Assistant Professional Staff (GIS Development)', value: 35, disabled: true}
        , {
          key: 6,
          text: 'Assistant Professional Staff (Junior System and Network Administrator)',
          value: 36, disabled: true
        }
        , {key: 7, text: 'Assistant Professional Staff (Database Administrator)', value: 37, disabled: true}
        , {key: 8, text: 'Assistant Professional Staff (Design Grafis dan Konten Kreator)', value: 38, disabled: true}
        , {key: 9, text: 'Ahli Multimedia', value: 39, disabled: true}
      ]
      this.setState({jabatanOptions: jabatan3});
    } else {
      this.setState({jabatanOptions: [], valueJabatan: "", showQuestions: false});
    }
  };
  handleOptionsChangeJabatan = (e, {value}) => {
    this.setState({valueJabatan: value});
    let jabatan = this.state.jabatanOptions.filter(entry => Object.values(entry).some(val => typeof val === "number" && val === value))
    this.setState({jabatan: jabatan});
    const modal = {
      isOpen: true,
      header: `Yakin ?`,
      content: `Apakah pilihan ` + jabatan[0].text + ` sudah benar ?`,
      onAction: this.handleActionModal,
      onClose: this.handleCloseModal
    };
    this.setState({modal});
  };
  handleActionModal = (response) => {
    if (response) {
      switch (this.state.valueJabatan) {
        case 11:
          this.props.history.push("/a1")
          break;
        case 12:
          this.props.history.push("/a2")
          break;
        case 13:
          this.props.history.push("/a3")
          break;
        case 21:
          this.props.history.push("/j1")
          break;
        case 22:
          this.props.history.push("/j2")
          break;
        case 23:
          this.props.history.push("/j3")
          break;
        case 24:
          this.props.history.push("/j4")
          break;
        case 25:
          this.props.history.push("/j5")
          break;
        case 26:
          this.props.history.push("/j6")
          break;
        case 27:
          this.props.history.push("/j7")
          break;
        case 28:
          this.props.history.push("/j8")
          break;
        case 29:
          this.props.history.push("/j9")
          break;
        case 210:
          this.props.history.push("/j10")
          break;
        case 211:
          this.props.history.push("/j11")
          break;
        case 212:
          this.props.history.push("/j12")
          break;
        case 213:
          this.props.history.push("/j13")
          break;
        case 214:
          this.props.history.push("/j14")
          break;
        case 215:
          this.props.history.push("/j15")
          break;
        case 216:
          this.props.history.push("/j16")
          break;
        case 217:
          this.props.history.push("/j17")
          break;
        case 31:
          this.props.history.push("/i1")
          break;
        case 32:
          this.props.history.push("/i2")
          break;
        case 33:
          this.props.history.push("/i3")
          break;
        case 34:
          this.props.history.push("/i4")
          break;
        case 35:
          this.props.history.push("/i5")
          break;
        case 36:
          this.props.history.push("/i6")
          break;
        case 37:
          this.props.history.push("/i7")
          break;
        case 38:
          this.props.history.push("/i8")
          break;
        case 39:
          this.props.history.push("/i9")
          break;
        case 310:
          this.props.history.push("/i10")
          break;
        case 40:
          this.props.history.push("/i11")
          break;
        default:
          break;
      }
    } else {
      this.setState({valueJabatan: ""})
    }
    this.setState({
      modal: {...this.modalInitialState}
    });
  };
  handleCloseModal = () => {
    this.setState({
      modal: {...this.modalInitialState}
    });
  };

  render() {
    const posisi = [
      {key: 3, text: 'Aplikasi dan Infrastruktur CKTRP', value: 3, disabled: false},
      {key: 1, text: 'Digitalisasi Arsip Dokumen Perizinan Tata Ruang dan Bangunan', value: 1, disabled: false},
      {key: 2, text: 'Sistem Informasi Geografis Jakarta Satu', value: 2, disabled: false}
    ]
    return (<Container className={"isi"}>
      <Segment raised>
        <Header size={"huge"} textAlign={"center"}>
          <Header.Content>
            Silahkan pilih Posisi dan Jabatan
          </Header.Content>
        </Header>
        <Grid columns="equal" verticalAlign="middle">
          <GridRow>
            <Grid.Column>
              <Dropdown
                fluid
                clearable
                options={posisi}
                search
                selection
                placeholder="Pilih Posisi"
                onChange={this.handleDropdownChange}
              />
            </Grid.Column>
            <Grid.Column>
              <Dropdown
                fluid
                options={this.state.jabatanOptions}
                placeholder="Pilih Jabatan"
                onChange={this.handleOptionsChangeJabatan}
                search
                selection
                scrolling
                value={this.state.valueJabatan}
              />
            </Grid.Column>
          </GridRow>
        </Grid>
      </Segment>
      <ConfirmationModal modal={this.state.modal}/>
    </Container>)
  }
}

export default Mulai;