import React, {Component} from "react";
import {Container, Dimmer, Header, Icon, Progress, Segment, Statistic} from "semantic-ui-react";
import Countdown from "react-countdown";
import json from "../data/jakartasatu/7.json";

class J7 extends Component {
  state = {
    percent: 100,
    id: 0,
    pertanyaan: "",
    date: 1
  }
  handleOnTick = ({total}) => {
    let persen = total / json[this.state.id - 1].waktu * 100;
    this.setState({percent: persen});
  }
  handleOnComplete = () => {
    if (this.state.id < json.length) {
      this.setState({
        percent: 100,
        id: json[this.state.id].id,
        pertanyaan: json[this.state.id].pertanyaan,
        date: Date.now() + json[this.state.id].waktu
      });
    } else {
      this.setState({dimmed: true})
    }
  };
  handleHide = () => {
    this.props.history.push('/');
  };

  render() {
    const renderer = ({hours, minutes, seconds}) => {
      return (<Statistic.Group widths='three'>
        <Statistic size={"small"}>
          <Statistic.Value>{hours}</Statistic.Value>
          <Statistic.Label>Jam</Statistic.Label>
        </Statistic>
        <Statistic size={"small"}>
          <Statistic.Value>{minutes}</Statistic.Value>
          <Statistic.Label>Menit</Statistic.Label>
        </Statistic>
        <Statistic size={"small"}>
          <Statistic.Value>{seconds}</Statistic.Value>
          <Statistic.Label>Detik</Statistic.Label>
        </Statistic>
      </Statistic.Group>);
    }
    return (<Container className={"isi"}>
      <Dimmer.Dimmable as={Segment} blurring dimmed={this.state.dimmed}>
        <Progress attached='top' percent={this.state.percent} indicating/>
        <Dimmer active={this.state.dimmed} onClickOutside={this.handleHide}>
          <Header as='h2' icon inverted>
            <Icon name='thumbs up outline'/>
            Wawancara Anda Telah Selesai.
          </Header>
        </Dimmer>
        <Segment stacked>
          <Header size='large' textAlign={"center"}>
            <Header.Content>
              Pertanyaan {this.state.id}
            </Header.Content>
          </Header>
        </Segment>
        <Segment placeholder>
          <Header size={"huge"} textAlign={"center"}>
            <p>{this.state.pertanyaan}</p>
          </Header>
        </Segment>
        <Segment raised>
          <Header size='medium' textAlign={"center"}>
            <Header.Content>
              Waktu Anda :
            </Header.Content>
          </Header>
          <Countdown key={this.state.id} intervalDelay={1000} date={this.state.date} renderer={renderer}
                     onComplete={this.handleOnComplete} onTick={this.handleOnTick}/>
        </Segment>
      </Dimmer.Dimmable>
    </Container>)
  }
}

export default J7;