import React from "react";
import './App.css';
import Navbar from "./components/Navbar";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./components/page/Home";
import Mulai from "./components/page/Mulai";
import A1 from "./components/page/A1";
import A2 from "./components/page/A2";
import A3 from "./components/page/A3";
import J4 from "./components/page/J4";
import J9 from "./components/page/J9";
import J10 from "./components/page/J10";
import J11 from "./components/page/J11";
import J12 from "./components/page/J12";
import J13 from "./components/page/J13";
import I1 from "./components/page/I1";
import I2 from "./components/page/I2";
import I3 from "./components/page/I3";
import I4 from "./components/page/I4";
import I5 from "./components/page/I5";
import I6 from "./components/page/I6";
import I7 from "./components/page/I7";
import I8 from "./components/page/I8";
import J1 from "./components/page/J1";
import J2 from "./components/page/J2";
import J3 from "./components/page/J3";
import J5 from "./components/page/J5";
import J6 from "./components/page/J6";
import J7 from "./components/page/J7";
import J8 from "./components/page/J8";
import J14 from "./components/page/J14";
import J15 from "./components/page/J15";
import J16 from "./components/page/J16";
import J17 from "./components/page/J17";
import I9 from "./components/page/I9";
import I10 from "./components/page/I10";
import I11 from "./components/page/I11";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Navbar/>
        <React.StrictMode>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/home" component={Home}/>
            <Route path="/mulai" component={Mulai}/>
            <Route path="/a1" component={A1}/>
            <Route path="/a2" component={A2}/>
            <Route path="/a3" component={A3}/>
            <Route path="/j1" component={J1}/>
            <Route path="/j2" component={J2}/>
            <Route path="/j3" component={J3}/>
            <Route path="/j4" component={J4}/>
            <Route path="/j5" component={J5}/>
            <Route path="/j6" component={J6}/>
            <Route path="/j7" component={J7}/>
            <Route path="/j8" component={J8}/>
            <Route path="/j9" component={J9}/>
            <Route path="/j10" component={J10}/>
            <Route path="/j11" component={J11}/>
            <Route path="/j12" component={J12}/>
            <Route path="/j13" component={J13}/>
            <Route path="/j14" component={J14}/>
            <Route path="/j15" component={J15}/>
            <Route path="/j16" component={J16}/>
            <Route path="/j17" component={J17}/>
            <Route path="/i1" component={I1}/>
            <Route path="/i2" component={I2}/>
            <Route path="/i3" component={I3}/>
            <Route path="/i4" component={I4}/>
            <Route path="/i5" component={I5}/>
            <Route path="/i6" component={I6}/>
            <Route path="/i7" component={I7}/>
            <Route path="/i8" component={I8}/>
            <Route path="/i9" component={I9}/>
            <Route path="/i10" component={I10}/>
            <Route path="/i11" component={I11}/>
          </Switch>
        </React.StrictMode>
      </BrowserRouter>
    </div>
  );
}

export default App;